import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';


export async function sendUtmSession(utm_session) {
  return await fetch(
    `${process.env.REACT_APP_API_URL}/private/unrestricted/utm_session/create`,
    {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "utm_information": utm_session }),
      cache: 'no-store'
    },
  );
}

const excluded_websites = process.env.REACT_APP_EXCLUDED_DOMAINS?.split(",")

const validateReferrer = (refferer) => {
  const found = excluded_websites.find((element) => refferer?.includes(element))
  return found ? null : refferer
}

const generateId = () => {
  try {
    return uuidv4()
  } catch {
    return Date.now().toString(36) + Math.random().toString(36).slice(2);
  }
};

const sanitize = (str) =>
  str
    ? String(str)
      .slice(0, 500)
      .replace(/[<>'"]/g, "")
      .trim()
    : null;

const getDeviceType = (ua) => {
  let deviceType = "desktop";
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    deviceType = "tablet";
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    deviceType = "mobile";
  } else if (/bot|crawler|spider|crawling/i.test(ua)) {
    deviceType = "bot";
  }
  return deviceType
}

const getBrowser = (ua) => {
  const browsers = {
    chrome: /chrome|chromium/i,
    safari: /safari/i,
    firefox: /firefox/i,
    edge: /edge/i,
    ie: /msie|trident/i,
    opera: /opera|opr/i,
  };
  for (const [name, regex] of Object.entries(browsers)) {
    if (regex.test(ua)) return name;
  }
  return "other";
};

//  Tracks user sessions with UTM parameters and referrer information.
//  Creates a new session if none exists, updates existing sessions, and returns the data.
//   @returns {Object|null} Session data or null if error occurs
export const trackSession = () => {
  try {
    const COOKIE_NAME = "ping_session_tracking";
    const COOKIE_DOMAIN = process.env.REACT_APP_UTM_COOKIE_DOMAIN;
    const COOKIE_LIFETIME = 10 * 365 * 24 * 60 * 60 // 10 years

    // Helper function to sanitize strings
    // Helper function to generate session ID

    const urlParams = new URLSearchParams(window.location.search);
    const currentReferrer = sanitize(document.referrer);
    const checkedRefferer = validateReferrer(currentReferrer)
    const isDirectTraffic = !checkedRefferer;
    const ua = navigator.userAgent.toLowerCase();
    const deviceType = getDeviceType(ua)
    const browser_type = getBrowser(ua)
    const utm_source = sanitize(urlParams.get("utm_source"));
    const utm_medium = sanitize(urlParams.get("utm_medium"));
    const utm_campaign = sanitize(urlParams.get("utm_campaign"));
    const utm_content = sanitize(urlParams.get("utm_content"));
    const currentTime = new Date().toISOString();
    // Set default source/medium based on referrer
    const defaultSource = isDirectTraffic ? "direct" : "referral";
    const defaultMedium = isDirectTraffic ? null : "referral";

    // Collect non-standard UTM parameters
    const otherUtms = {};
     // @ts-ignore
        for (const [key, value] of urlParams.entries()) {
            if (
                key.startsWith('utm_') &&
                !['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'].includes(key)
            ) {
                otherUtms[key.replace('utm_', '')] = sanitize(value);
            }
        }

    // Check for existing session
    const existingCookie = document.cookie.match(
      new RegExp(`${COOKIE_NAME}=([^;]+)`)
    );

    const newSession = {
      session_id: generateId(),
      client_id: generateId(),
      session_start: currentTime, // Set once when creating new session
      last_activity: currentTime, // Will be updated on subsequent visits

      // UTM Parameters - use provided values or defaults based on referrer
      utm_source: utm_source || defaultSource,
      utm_medium: utm_medium || defaultMedium,
      utm_campaign: utm_campaign,
      utm_content: utm_content,
      utm_term: sanitize(urlParams.get("utm_term")),
      utm_other: Object.keys(otherUtms).length > 0 ? otherUtms : null, // Keep as object instead of string

      // Click IDs
      gclid: sanitize(urlParams.get("gclid")),
      fbclid: sanitize(urlParams.get("fbclid")),
      msclkid: sanitize(urlParams.get("msclkid")),

      // Affiliate tracking
      affiliate_tag: sanitize(
        urlParams.get("aff") ||
        urlParams.get("via") ||
        (urlParams.get("ref"))
      ),
      referrer: checkedRefferer,
      landing_page: window.location.origin + window.location.pathname,
      path: sanitize(window.location.pathname),

      // Device Data
      device_type: deviceType,
      browser_type: browser_type,
    };

    if (existingCookie) {
      let currentSession = JSON.parse(decodeURIComponent(existingCookie[1]));

      currentSession.last_activity = currentTime

      if (!currentSession.client_id) {
        currentSession.client_id = generateId()
      }

      // Add a check to see if their current refferer is invalid
      const checkedCurrentRefferer = validateReferrer(currentSession.refferer)
      if (checkedCurrentRefferer != currentSession.refferer) {
        currentSession.refferer = checkedCurrentRefferer
      }

      if (checkedRefferer && currentSession.referrer != checkedRefferer) {
        const updatedSession = {
          ...newSession,
          client_id: currentSession.client_id,
        }

        sendUtmSession(updatedSession)
        currentSession = updatedSession
      }

      document.cookie = `${COOKIE_NAME}=${encodeURIComponent(
        JSON.stringify(currentSession)
      )}; max-age=${COOKIE_LIFETIME}; path=/; domain=${COOKIE_DOMAIN}; SameSite=Lax; Secure`;

      return currentSession

    }

    const cookieValue = encodeURIComponent(JSON.stringify(newSession));

    // Check cookie size limit (4KB to be safe)
    if (cookieValue.length <= 4096) {
      document.cookie = `${COOKIE_NAME}=${cookieValue}; max-age=${COOKIE_LIFETIME}; path=/; domain=${COOKIE_DOMAIN}; SameSite=Lax; Secure`;
    } else {
      // If too large, store minimal version
      const minimalSession = { ...newSession };
      delete minimalSession.utm_other;
      delete minimalSession.browser_type;
      document.cookie = `${COOKIE_NAME}=${encodeURIComponent(
        JSON.stringify(minimalSession)
      )}; max-age=${COOKIE_LIFETIME}; path=/; domain=${COOKIE_DOMAIN}; SameSite=Lax; Secure`;
    }

    sendUtmSession(newSession)
    return newSession;
  } catch (error) {
    console.error("Session tracking error:", error);
    return null;
  }
};

const UtmTracker = () => {
    useEffect(() => {
        trackSession();
    }, []);

    return null;
};

export default UtmTracker;
